import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({ closeSidebar }) => {
  return (
    <div>
      <div className="full-bac">
        <div className="sidenav-white open">
          <button
            className="close-btn-white"
            id="button"
            onClick={closeSidebar}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {/* form */}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
